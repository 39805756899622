<template>
  <div class="oil">
    <div class="swiper-warp">
      <nHeader :name="url === 'information'"></nHeader>

      <div class="oilcontent">
        <div class="oillist" v-for="(item, index) in pricetext" :key="index">
          <div class="oiltitel">
            <div class="titeltext">
              <h3>{{ item.name }}</h3>
              <p style="color: #999">更新时间：{{ item.timePoint }}</p>
            </div>
            <!-- <div><van-button type="primary" size="small">刷新</van-button></div> -->
          </div>
          <div class="oilprice">
            <h3 style="margin: 0.2rem 0">市场均价</h3>
            <div class="price">
              <div class="pricetext">
                <h3>废机油</h3>
                <p>最新均价</p>
              </div>
              <div
                style="
                  padding: 0 1.2rem;
                  border-left: 1px solid #999;
                  border-right: 1px solid #999;
                "
                class="pricetext"
              >
                <h3>{{ item.average }}元/桶</h3>
                <p>当前均价</p>
              </div>
              <div class="pricetext">
                <h3>{{ item.lastMonthAverage }}元/桶</h3>
                <p>上月均价</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, onMounted, toRefs } from 'vue'
import nHeader from '@/components/nHerder'
import { priceList } from '@/service/Information'
export default {
  components: {
    nHeader
  },
  setup() {
    const state = reactive({
      pricetext: []
    })
    onMounted(() => {
      priceData()
      console.log(state.pricetext)
    })
    const priceData = async () => {
      await priceList().then((res) => {
        state.pricetext = res.data
      })
    }
    return {
      ...toRefs(state),
      priceData
    }
  }
}
</script>

<style  lang="less" scoped>
h3 {
  margin: 0;
}
.swiper-warp {
  background-image: linear-gradient(180deg, #eff3fe, #eff3fe, #ffffff, #ffffff);
}
.oilcontent {
  margin-top: 80px;
  padding: 0 0.1rem 0.2rem 0.1rem;
  .oillist {
    padding: 0.4rem 0.2rem;
    margin: 0.2rem 0;
    color: #000;
    box-shadow: 2px 2px 5px #c1c1c1;
    border-radius: 5px;
    .oiltitel {
      display: flex;
      justify-content: space-between;
      .titeltext {
        h3 {
          margin: 0;
        }
        p {
          margin: 0;
        }
      }
    }
    .price {
      display: flex;
      justify-content: space-between;
      .pricetext {
        h3 {
          margin: 0;
        }
        p {
          margin: 0;
          color: #999;
        }
      }
    }
  }
}
</style>
