<template>
  <div class="Wenku">
    <div class="swiper-warp">
      <nHeader :name="'information'"></nHeader>
      <div class="wenku-list">
        <form action="/">
          <van-search
            v-model="value"
            placeholder="请输入搜索关键词"
            @update:model-value="onSearch"
            @cancel="onCancel"
          />
        </form>
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
          <van-list
            v-model:loading="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
            @offset="10"
          >
            <div
              class="wenkulist"
              v-for="item in wkData"
              :key="item.index"
              @click="tolink(item.fileHtmlUrl)"
            >
              <van-card :title="item.title" :thumb="item.coverDrawingUrl">
                <!-- <template #bottom>
                    <van-tag color="#d9ecf4" text-color="#03b5ff">标签</van-tag>
                    <van-tag color="#d9ecf4" text-color="#03b5ff">标签</van-tag>
                  </template> -->
                <template #tags>
                  <p>{{ item.publishTime }}</p>
                </template>
                <!-- <template #footer>
                    <van-button size="mini">按钮</van-button>
                    <van-button size="mini">按钮</van-button>
                  </template> -->
              </van-card>
            </div>
          </van-list>
        </van-pull-refresh>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, onMounted, toRefs } from 'vue'
import { getLibrarys } from '@/service/Information'
import nHeader from '@/components/nHerder'

export default {
  components: {
    nHeader
  },
  setup() {
    const state = reactive({
      img1: require('@/assets/img1.png'),
      wkData: [],
      currPage: 1,
      totalPage: 0,
      loading: false,
      finished: false,
      refreshing: false,
      value: ''
    })

    onMounted(() => {
      onRefresh()
    })

    const wkList = async () => {
      const {
        data,
        data: { list }
      } = await getLibrarys({
        params: { pageNumber: state.currPage, pageSize: 10, title: state.value }
      })
      state.wkData = state.wkData.concat(list)
      state.totalPage = data.totalPage
      state.loading = false
      if (state.currPage >= data.totalPage) state.finished = true
    }

    const onLoad = () => {
      if (!state.refreshing && state.currPage < state.totalPage) {
        state.currPage = state.currPage + 1
      }
      if (state.refreshing) {
        state.wkData = []
        state.refreshing = false
      }
      console.log(!state.refreshing, state.currPage, state.totalPage)
      wkList()
    }

    const onRefresh = () => {
      state.refreshing = true
      state.finished = false
      state.loading = true
      state.currPage = 1
      onLoad()
    }

    const tolink = (url) => {
      window.open(url)
    }

    const onSearch = (val) => {
      if (val) {
        state.wkData = []
        state.currPage = 1
      } else {
        state.wkData = []
        state.finished = false
        state.currPage = 1
      }

      wkList()
    }
    const onCancel = () => {}
    return {
      ...toRefs(state),
      wkList,
      tolink,
      onRefresh,
      onLoad,
      onCancel,
      onSearch
    }
  }
}
</script>

<style lang="less" scoped>
:deep(.van-card) {
  background: none;
}
:deep(.van-search) {
  background: none;
}
.swiper-warp {
  background-image: linear-gradient(180deg, #eff3fe, #eff3fe, #ffffff, #ffffff);
  .herder {
    display: flex;
    height: 80px;
    justify-content: space-between;
  }
  .informatin-logo {
    padding: 0.4rem;
  }
  .herdertext {
    line-height: 80px;
    color: #000;
    font-size: 14px;
    padding-right: 0.4rem;
  }
}
.wenku-list {
  margin-top: 80px;
  padding: 0 0.1rem 0.2rem 0.1rem;
  height: calc(~'(100vh - 90px)');
  overflow: hidden;
  overflow-y: scroll;
  padding: 0 0.2rem 0.2rem 0.2rem;
  .wenkulist {
    padding: 0.2rem;
    margin: 0.2rem 0;
    color: #000;
    box-shadow: 2px 2px 5px #c1c1c1;
    border-radius: 5px;
    h3 {
      margin: 0;
      margin-bottom: 0.2rem;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden; //溢出内容隐藏
      text-overflow: ellipsis; //文本溢出部分用省略号表示
      display: -webkit-box; //特别显示模式
      -webkit-line-clamp: 1; //行数
      line-clamp: 1;
      -webkit-box-orient: vertical; //盒子中内容竖直排列
    }
    img {
      width: 100%;
      height: 130px;
    }
    .wenkucontent {
      padding: 0.1rem 0;

      .wenkudescribe {
        span {
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden; //溢出内容隐藏
          text-overflow: ellipsis; //文本溢出部分用省略号表示
          display: -webkit-box; //特别显示模式
          -webkit-line-clamp: 3; //行数
          line-clamp: 3;
          -webkit-box-orient: vertical; //盒子中内容竖直排列
          color: #999;
          line-height: 0.5rem;
        }
      }
      .wenkulabel {
        span {
          margin-right: 5px;
          border: none;
        }
      }
    }
  }
}
</style>