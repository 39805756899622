<template>
  <div class="News">
    <div class="swiper-warp">
      <nHeader :name="url === 'information' ? 'information' : 'news'"></nHeader>

      <div class="news-content">
        <div class="newstitle">
          <h2>{{ newsData.contentTitle }}</h2>
        </div>
        <div class="newsauthor">
          <span style="margin-right: 10px"
            >来源：{{ newsData.contentSource }}</span
          >作者：{{ newsData.contentAuthor }}
        </div>
        <div class="newscontent" v-html="newsData.contentDetails"></div>
        <div class="news-footer" style="margin-top: 50px;">
            <h3>版权声明：</h3>
            <p style="text-indent: 2em;font-size:14px">
              油途早报是为方便环保同行及时获取环保领域信息和资讯的免费公益信息分发平台，所分发的内容版权完全归属于来源出处，不代表我们的立场和观点，如所分发的内容侵犯了您的权益，请联系xiyin.luo@oiltour.com.cn，我们会及时处理。
            </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, onMounted, toRefs } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { content } from '@/service/Information'
import nHeader from '@/components/nHerder'

export default {
  components: {
    nHeader
  },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const { id, url } = route.query
    const state = reactive({
      img1: require('@/assets/img1.png'),
      logo: require('@/assets/logo.png'),
      logotitle: require('@/assets/logotitle.png'),
      newsData: {},
      url: url
    })
    onMounted(() => {
      newscontent()
    })
    const newscontent = () => {
      content(id).then((res) => {
        state.newsData = res.data
      })
    }

    const tohome = () => {
      router.push({ name: 'information' })
    }
    return {
      ...toRefs(state),
      newscontent,
      tohome
    }
  }
}
</script>

<style lang="less" scoped>
.swiper-warp {
  background-image: linear-gradient(180deg, #eff3fe, #eff3fe, #ffffff, #ffffff);
}
.news-content {
  margin-top: 80px;

  padding: 0.4rem;
  .newscontent {
  }
}
</style>