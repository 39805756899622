<template>
  <div class="main">跳转中...</div>
  <!-- <div class="main" @click="goWxminPay">跳转中...</div> -->
</template>

<script>
import { reactive, toRefs } from 'vue'
import { onMounted } from 'vue'
import { useRoute } from 'vue-router'

export default {
  name:'Transfer',
  setup() {
    const route = useRoute()
    const state = reactive({
      params: '',
    })

    onMounted(() => {
      console.log('onMounted....',route.query.paySign)
      
      // const { paySign } = JSON.parse(decodeURIComponent(route.query))


      /* const { appId,timeStamp, nonceStr, signType, paySign } = JSON.parse(route.query.paySign).paySign

      let packageVal = JSON.parse(route.query.paySign).packages
      console.log('packageVal:', packageVal); */


      setTimeout(()=>{
        window.wx.miniProgram.navigateTo({
          // url: `/pages/pay/index?orderId=${orderNo}&amount=${amount}&sign=${sign}`
          url: `/pages/pay/icbc?paySign=${encodeURIComponent(route.query.paySign)}`
          // url: `/pages/pay/icbc?paySign=%7B%22timeStamp%22%3A%221685001109%22%2C%22packages%22%3A%22prepay_id%3Dwx25155149094116f56d1225b0bc3cd90000%22%2C%22paySign%22%3A%22A3D59B2545815C7F7627C2FD012DDEAA%22%2C%22appId%22%3A%20%22wx9715dadf976e5b1c%22%2C%22signType%22%3A%20%22MD5%22%2C%22nonceStr%22%3A%20%22G1Rqo2SINnmyd7WaiZTDCcItK2vRQPcN%22%7D`

        })
        console.log('end....');
        
      }, 50)
      // goWxminPay()
    })
    const goWxminPay = ()=>{
      console.log('goWxminPay....',route.query.paySign)
      // alert('goWxminPay....');
    }
    /* const handlePayOrder = (orderId, orderAmount, sign) => {
      console.log(sign)
      console.log('支付中....')
      let orderNo = orderId
      let amount = orderAmount
      window.wx.miniProgram.navigateTo({
        // url: `/pages/pay/index?orderId=${orderNo}&amount=${amount}&sign=${sign}`
        url: `/pages/pay/index?orderId=${orderNo}&amount=${amount}&sign=${sign}`
      })
    } */

    return {
      ...toRefs(state),
      goWxminPay
      // handlePayOrder
    }
    
  }
}
</script>

<style>

</style>