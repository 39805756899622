<template>
  <div>
    <div class="swiper-warp">
      <nHeader :name="'information'"></nHeader>

      <div class="news-content">
        <div class="newstitle">
          <h2>商务合作</h2>
        </div>
        <div class="newscontent">
          <p>感谢关注油途OilTour</p>
          <p>
            ♻
            油途OilTour是一个智能环保综合服务平台，它可帮您有效节省90%的环保管理时间、80%的环保服务成本！
            ♻
          </p>
          <img style="width: 100%" :src="img2" alt="" />

          <p>在这里，</p>
          <p>油途商城为您 带来多元环保服务的快捷体验；</p>
          <p>设备管理助您 轻松发起联单，一键导出台账；</p>
          <p>油途资讯为您 提供最及时、最热门的前沿信息；</p>
          <p>在PC端，油途更能为产业链参与者提供交易、结算一站式系统服务。</p>
          <p>招商事宜，可发邮件至wenfeng.li@oiltour.com.cn</p>
          <p>如需要电话沟通，可拨打 400-801-3838</p>
          <img style="width: 100%" :src="img3" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import nHeader from '@/components/nHerder'

export default {
  components: {
    nHeader
  },
  setup() {
    const state = reactive({
      img1: require('@/assets/img1.png'),
      img2: require('@/assets/oildata.png'),
      img3: require('@/assets/oillink.png'),
      newsData: []
    })
    return {
      ...toRefs(state)
    }
  }
}
</script>

<style lang="less" scoped>
.swiper-warp {
  background-image: linear-gradient(180deg, #eff3fe, #eff3fe, #ffffff, #ffffff);
}
.news-content {
  margin-top: 80px;
  padding: 0.4rem;
  .newscontent {
  }
}
</style>